div .card {
    border: none;
    padding: 2rem 0;
    border-radius: 0;

}

.card .card-body{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.card .card-img{
    margin: 0 auto;
    margin-bottom: 1rem;
}

.imgLink {
    display: block;
}

/* fontawesome icon */
svg {
    margin: 0 .5rem;
}

a.projLink.card-link {
    color: #FBAB7E;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
}

.card-link:hover {
    color: #F7CE68;
    animation: pulse 5s alternate;
    animation-iteration-count: infinite; 
}

/* card links box */
.infobox div {
    padding: 0;
    display: flex;
    flex-direction: column;
}